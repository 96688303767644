import React from "react"
import Layout from "../components/layout/layout"

const NotFoundPage = () => (
  <Layout title="404">
    <section>
      <h1>Not Found</h1>
      <p>You just hit a page that doesn&#39;t exist</p>
    </section>
  </Layout>
)

export default NotFoundPage
